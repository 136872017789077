<template>
  <el-main>
    <el-form class="el-form-search" label-width="110px">
      <el-form-item label="员工昵称：">
        <el-input size="small" placeholder="请输入昵称" v-model="searchForm.nickname"></el-input>
      </el-form-item>
      <el-form-item label="员工手机号：">
        <el-input size="small" placeholder="请输入昵称" v-model="searchForm.mobile"></el-input>
      </el-form-item>
      <el-form-item label="加入时间：">
        <el-date-picker
          v-model="searchForm.time"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          size="small"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item label=" " label-width="20px">
        <el-button type="primary" size="small" @click="getList()">搜索</el-button>
        <el-button type="text" size="small" @click="cancelSearch()">清空搜索条件</el-button>
      </el-form-item>
    </el-form>
    <el-table :data="dataList" :header-cell-style="{ 'background-color': '#F8F9FA' }">
      <template slot="empty">
        <No />
      </template>
      <el-table-column prop="name" label="用户信息" align="center">
        <template v-slot="{ row }">
          <UserInfo :name="row.nickname" :avatar="row.avatar"></UserInfo>
        </template>
      </el-table-column>
      <el-table-column prop="mobile" label="手机号" align="center"> </el-table-column>
      <el-table-column label="员工身份" align="center">
        <template v-slot="{ row }">
          {{ row.shareholder ? '区域经理' : '员工' }}
        </template>
      </el-table-column>
      <el-table-column label="代理区域" align="center">
        <template v-slot="{ row }">
          {{ row.shareholder ? row.area_name : '--' }}
        </template>
      </el-table-column>
      <el-table-column label="累计积分" align="center">
        <template v-slot="{ row }">
          {{ Number(row.commission_amount) + Number(row.goods_commission_amount) }}
        </template>
      </el-table-column>
      <el-table-column
        prop="can_withdraw_amount"
        label="现有积分数"
        align="center"
      ></el-table-column>
      <el-table-column prop="user_count" label="累计邀请员工" align="center"> </el-table-column>
      <el-table-column label="注册时间" align="center">
        <template v-slot="{ row }">
          {{ getDateformat(row.create_time) }}
        </template>
      </el-table-column>
      <el-table-column prop="name" label="操作" align="center">
        <template v-slot="{ row }">
          <el-button type="text" size="small" @click="updateStartFn(row)">修改身份</el-button>
        </template>
      </el-table-column>
    </el-table>
    <Paging :total="total" :page="page" :pageNum="rows" @updatePageNum="updateData"></Paging>
    <!-- 修改身份弹框 -->
    <el-dialog title="修改身份" :visible.sync="updateDialogFlag" width="600px">
      <el-form ref="form1" label-width="100px">
        <el-form-item label="员工昵称："> {{ updateItem.nickname }}</el-form-item>
        <el-form-item label="员工身份：">普通员工 </el-form-item>
        <el-form-item label="修改身份为："> 区域经理</el-form-item>
        <el-form-item label="代理区域：">
          <el-cascader
            v-model="chooseCity"
            :props="{ value: 'id', label: 'name', children: '_child' }"
            placeholder="请选择或搜索"
            clearable
            filterable
            :options="cityList"
          ></el-cascader>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="updateDialogFlag = false">取 消</el-button>
        <el-button type="primary" @click="sureUpdate()">确 定</el-button>
      </span>
    </el-dialog>
  </el-main>
</template>

<script>
import _ from 'lodash'
import Paging from '@/components/paging'
import { getDateformat } from '@/util/getDate.js'
import UserInfo from '@/components/userInfo'
export default {
  components: {
    Paging,
    UserInfo
  },
  data() {
    return {
      getDateformat,
      searchForm: {
        nickname: '',
        time: [],
        mobile: ''
      },
      dataList: [],
      page: 1,
      rows: 10,
      total: 0,
      updateDialogFlag: false,
      updateItem: {},
      chooseCity: null,
      cityList: null
    }
  },
  created() {
    this.getCity()
    this.getList()
  },
  methods: {
    updateData(val, status) {
      if (status == 0) {
        this.rows = val
      } else {
        this.page = val
      }
      this.getList()
    },
    getList() {
      let obj = {
        page: this.page,
        rows: this.rows,
        status: 0
      }
      if (this.searchForm.nickname) {
        obj.nickname = this.searchForm.nickname
      }
      if (this.searchForm.mobile) {
        obj.mobile = this.searchForm.mobile
      }
      if (this.searchForm.time && this.searchForm.time.length == 2) {
        obj.start_time = Math.ceil(this.searchForm.time[0].getTime() / 1000)
        obj.end_time = Math.ceil(this.searchForm.time[1].getTime() / 1000)
        if (obj.start_time == obj.end_time) {
          obj.end_time += 60 * 60 * 24 - 1
        }
      }
      this.$axios.post(this.$api.push.regionalShareholders.getDistrbution, obj).then((res) => {
        if (res.code == 0) {
          this.dataList = res.result.list
          this.total = res.result.total_number
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    getCity() {
      this.$axios.get(this.$api.set.area, { tree: 1, level: 2 }).then((res) => {
        if (res.code == 0) {
          this.cityList = res.result.list
        }
      })
    },
    cancelSearch() {
      this.page = 1
      this.searchForm = {
        info: '',
        time: []
      }
      this.getList()
    },
    updateStartFn(row) {
      this.updateItem = row
      if (row.shareholder) {
        this.chooseCity = [
          row.shareholder.province_id,
          row.shareholder.city_id,
          row.shareholder.area_id
        ]
      } else {
        this.chooseCity = null
      }

      this.updateDialogFlag = true
    },
    sureUpdate() {
      if (!this.chooseCity || this.chooseCity.length != 3) {
        this.$message.error('请选择地区')
        return
      }
      // 调用接口成为区域代理
      this.$axios
        .post(this.$api.push.regionalShareholders.upIdentity, {
          id: this.updateItem.id,
          status: 1,
          province_id: this.chooseCity[0],
          city_id: this.chooseCity[1],
          area_id: this.chooseCity[2]
        })
        .then((res) => {
          if (res.code == 0) {
            this.$message.success('升级成功')
            this.updateItem = {}
            this.updateDialogFlag = false
            this.$router.push('/extension/regionalDividend/auditArea')
          } else {
            this.$message.error(res.msg)
          }
        })
    }
  }
}
</script>

<style lang="less" scoped>
.el-main {
  background-color: #fff;
}
</style>
